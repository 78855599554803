import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=9641ab90&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/furanet/sites/runnea.fr/web/htdocs/nuxt/components/AppLink.vue').default,BrandsCarousel: require('/furanet/sites/runnea.fr/web/htdocs/nuxt/components/BrandsCarousel.vue').default})
